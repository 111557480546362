<template>
  <div class="dev-sessions">
    <table class="table">
      <thead>
      <tr>
        <th>id</th>
        <th>{{ $t('views.dev_sessions.dev_sessions_index.name') }}</th>
        <th>{{ $t('views.dev_sessions.dev_sessions_index.surname') }}</th>
        <th>{{ $t('views.dev_sessions.dev_sessions_index.roles') }}</th>
        <th>{{ $t('views.dev_sessions.dev_sessions_index.action') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="user in users" :key="user.id" class="mb-2">
        <td>{{ user.id }}</td>
        <td>{{ user.firstName }}</td>
        <td>{{ user.lastName }}</td>
        <td>{{ user.roles }}</td>
        <td>
          <pcg-btn size="small" @click="login(user.id, user.roles)">{{ $t('views.login.login_btn') }}</pcg-btn>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import ApiDevSessions from '../../api/dev_sessions'
import ApiAuth from '../../api/auth'
import { mapGetters } from 'vuex'
// import { PcgBtn } from 'pcg-core-vue'

export default {
  name: 'DevSessionsIndex',
  components: { },
  mixins: [],
  data: function () {
    return {
      localPassword: '',
      emailLogin: '',
      localLogin: false,
      loginView: false,
      users: []
    }
  },
  computed: {
    ...mapGetters('auth', ['signedIn', 'currentRole', 'expire'])
  },
  created () {
    if (this.signedIn && this.expire) {
      this.$store.dispatch('auth/signOut')
      this.$router.push({ name: 'login_path' })
    } else {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
      if (this.signedIn) {
        this.$toastr.i(this.$t('views.dev_sessions.dev_sessions_index.logged_in'))
        this.$router.push({ name: 'users_list_path' }).catch((err) => {
          throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
        })
      }
    }
  },
  mounted () {
    // eslint-disable-next-line eqeqeq
    if (process.env.LOGIN_VIEW == '1') {
      this.loginView = true
    } else if (process.env.LOCAL_LOGIN == '1') {
      this.loginView = false
      this.localLogin = true
      this.loadUsers()
    } else {
      this.localLogin = false
      this.loadUsers()
    }
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dev_sessions.dev_sessions_index.title')
      })
    },
    login (userId) {
      if (process.env.RAILS_ENV === 'development' || this.localLogin === true || process.env.LOCAL_LOGIN == '1') {
        this.setLogin(userId)
      }
    },
    setLogin (userId) {
      this.$store.dispatch('auth/devSignIn', { id: userId })
          .then(response => {
            const redirectTo = this.$store.getters['auth/getRedirectTo']
            if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
            } else if (redirectTo && redirectTo.name) {
              this.$store.dispatch('auth/clearRedirectTo')
              this.$router.push(redirectTo)
            } else {
              // TODO go to user dashboard
              this.$router.push({ name: 'root_path' }).catch(error => {
                console.log(error.message)
              })
            }
          })
          .catch(error => {
            console.log(error.message)
            this.$toastr.e(error.message)
          })
    },
    loadUsers () {
      ApiDevSessions.getUsers()
          .then(response => {
            this.users = response.data.data.map(user => { return { id: user.id, ...user.attributes } })
          })
          .catch(error => {
            console.log(error.response)
          })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/stylesheets/vars';

.password {
  ::v-deep .form-group {
    margin: 0.71rem 0;
    width: 11rem;
  }
}
</style>
