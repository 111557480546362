import api from './index'

const resource = '/users'

export default {
  signIn (params) {
    return api.get(`${resource}/auth/openid_connect/callback`, {
      params: params,
      baseURL: '/'
    })
  },
  devSignIn (payload) {
    return api.post('dev_sessions', payload)
  },
  redirSignIn (payload) {
    return api.post('redir_sessions', payload)
  },
  signOut () {
    return api.delete(`${resource}/sign_out`, {
      baseURL: '/'
    })
  },
  fetchUser () {
    return api.get(`${resource}/initial_check`)
  },
  setCurrentRole (currentRole) {
    const body = {
      current_role: currentRole
    }
    return api.post(`${resource}/set_current_role`, body)
  }
}
