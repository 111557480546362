export const general = {
  accept: 'Akceptuj',
  accuracy: 'trafność',
  action_cannot_be_reverted: 'Ta akcja nie może zostać cofnięta!',
  active: 'aktywne',
  add: 'Dodaj',
  add_attachment: 'Dodaj załącznik',
  all: 'Wszystkie',
  and: 'i',
  archive: 'Archiwizuj',
  are_you_sure: 'Jesteś pewien?',
  authors: 'Autorzy',
  avatar: 'Awatar',
  back: 'Powrót',
  calendar: 'Kalendarz',
  cancel: 'Anuluj',
  change: 'Zmień',
  change_contrast: 'Zmień kontrast',
  change_order: 'Zmień kolejność',
  change_to: 'zmień na',
  check_errors: 'Sprawdź błędy',
  clear: 'Wyczyść',
  clear_institution: 'Wyczyść dane instytucji',
  clear_filters: 'Wyczyść filtry',
  collapse: 'Zwiń',
  confirm: 'Zatwierdź',
  content: 'Treść',
  copy: 'Kopiuj',
  download_json: 'Pobierz JSON',
  decrease_font: 'Zmniejsz font',
  delete: 'Usuń',
  edit: 'Edytuj',
  edit_institution: 'Edytuj dane uczelni',
  edit_polon: 'Edytuj dane POL-on',
  enlarge_font: 'Zwiększ font',
  forbidden: 'Nie masz uprawnień do wykonania tej czynności.',
  lock: 'Blokuj',
  main_menu: 'Menu główne',
  no: 'nie',
  no_rights: 'Nie masz dostępu do tej części aplikacji',
  ok: 'OK',
  pagination_summary: '{from} - {to} z {total}',
  per_page: 'Wyników na stronę',
  roles: {
    employee: 'pracownik',
    systemadministrator: 'administrator systemu',
    systemmanager: 'menadżer systemu',
    unitadministrator: 'administrator jednostki'
  },
  return: 'Wróć',
  select: 'Wybierz',
  sis_import_blocked: 'Możliwość eksportu dla danego importu została zablokowana, ponieważ nowszy import został wyeksportowany.',
  skip_to_content: ' Przejdź do treści',
  unlock: 'Odblokuj',
  yes: 'tak'
}