export const components = {
  navigation_menu: {
    exports: 'Exports',
    imports: 'Imports',
    import_initialization: 'Import initialization',
    institutions: 'Institutions',
    integrations: 'Integrations',
    log_in: 'Login',
    logout: 'Log out',
    polon: 'POL-on 2.0',
    sis_credentials: 'SIS configuration data',
    students: "Students",
    token: 'Token',
    users: 'Users',
  },
  pcg_header_profile_button: {
    logout: 'Logout',
    role_change_to: 'Change role to {role}'
  },
  pcg_multi_select: {
    number_of_options: ' | {count} option was selected | {count} options were selected',
  },
  pcg_pagination_summary: {
    summary: '{from} - {to} of {total}'
  },
  ipo_field: {
    add: '+ Add',
  }
}