import api from './index'

const resource = 'sis_credentials'

export default {
  updateSisCredentials(data) {
    const body = {
      credentials: {
        username: data.username,
        password: data.password,
        scope: data.scope,
        client_id: data.clientId,
        client_secret: data.clientSecret,
        token_endpoint: data.tokenEndpoint,
        structureEndpoint: data.structureEndpoint,
        generatePackageEndpoint: data.generatePackageEndpoint,
        downloadPackageEndpoint: data.downloadPackageEndpoint
      }
    }
    let url = resource
    url += '/update_sis_credentials'
    return api.post(url, body)
  },
  getSisCredentials(data) {
    let url = resource
    url += '/get_sis_credentials'
    return api.get(url)
  }
}