<template>
  <div class="publication-history">
    <div class="history-elements">
      <div class="history-element" v-for="(history, index) in studentErrors" :key="index">
        <student-error-element :history="history" :course-index="index+1" :all-students-errors-from-import="allStudentsErrorsFromImport" />
      </div>
    </div>
  </div>
</template>

<script>
import StudentErrorElement from './StudentErrorElement'
export default {
  components: { StudentErrorElement },
  name: 'StudentErrors',
  props: {
    studentErrors: {
      type: Array,
    },
    allStudentsErrorsFromImport: Boolean
  }
}
</script>

<style>
  .dropdown-toggle{
  box-shadow: none !important;
}
</style>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .publication-history {
    .title {
      .modal-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .publication-title {
        font-weight: bold;
        color: $pcg-dark-gray;
      }
    }
    .history-elements {
      margin-top: 2rem;
    }
    .element-author {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      .author {
        .author-name {
          font-weight: bold;
          color: $main-active-color;
        }
        .changes-text {
          color: $pcg-dark-gray;
        }
      }
      .date {
        color: $pcg-gray;
        font-weight: 400;
      }
    }
    .element-changes {
      .key {
        color: $pcg-dark-gray;
        margin-bottom: .2rem;
      }
      .new-value {
        color: $alert-success;
        background-color: lighten($alert-success, 35%);
        margin-bottom: .5rem;
        border-radius: 5px;
        padding: .5rem 1rem;
        font-weight: 400;
      }
      .old-value {
        color: $alert-error;
        background-color: lighten($alert-error, 25%);
        border-radius: 5px;
        padding: .5rem 1rem;
        font-weight: 400;
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .publication-history {
      .title {
        .publication-title {
          color: $pcg-dark-gray;
        }
      }
      .element-author {
        .author {
          .author-name {
            color: $main-active-color;
          }
          .changes-text {
            color: $pcg-dark-gray;
          }
        }
        .date {
          color: $pcg-gray;
        }
      }
      .element-changes {
        .key {
          color: $pcg-dark-gray;
        }
        .new-value {
          color: $alert-success;
          background-color: lighten($alert-success, 35%);
        }
        .old-value {
          color: $alert-error;
          background-color: lighten($alert-error, 25%);
        }
      }
    }
  }
</style>
