<template>
  <b-dropdown class="student-select-group" menu-class="pcg-profile-container" :no-caret="true" right>
    <template v-slot:button-content class=''>
      <div class='student-option-select-icon'>
        <i class="fas fa-ellipsis-h"></i>
      </div>
    </template>
    <b-dropdown-item 
      v-for="option in options"
      :key='option'
      @click="$emit(option)"
      class="pcg-profile-option"
    >
      <div class="pcg-profile-option-txt">
        <span>
          {{$t(`views.students.list.${option}`)}}
        </span>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'student-options-select',
  props: {
    options: {
      type: Array,
      default: () => [],
      require: true
    }
  }
}
</script>

<style scoped lang='scss'>
.student-select-group{
  max-height: 350px;
  overflow: unset;
  :deep(button){
    width: 2rem;
    height: 2rem;
    max-height: 2rem;
    max-width: 2rem;
    background: #ECF9FD;
    border-color: #ECF9FD;
    border-radius: 50%;
    i{
      color: #31B3EB;
      transform: translate(-5px, -3px);
    }
  }
  :deep(.pcg-profile-container){
    overflow: unset !important;
    span{
      border: 0;
      line-height: 1rem;
      color: #B4B4B4;
      padding: 1.5rem;
    }
  }
}

</style>
