<template>
  <div class="redirect-view">
    <ipo-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
      <div class="no-rights">{{ $t('views.redirect_view.text') }}</div>
    </div>
  </div>
</template>

<script>
import loaderDelay from '../mixins/loader_delay'
import IpoLoader from '../components/IpoLoader'
export default {
  name: 'RedirectView',
  mixins: [loaderDelay],
  components: { IpoLoader },

  mounted () {
    setTimeout(() => {
      this.loadingDone = true
    }, 60000)
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.redirect_view.title'),
      subtitle: this.$t('views.redirect_view.subtitle'),
      capitalizeSubtitle: false
    })
  }
}
</script>

<style scoped lang="scss">
@import '../assets/stylesheets/vars';
.contrast{
  ::v-deep .redirect-view{
    background-color: $pcg-the-lightest-gray;
  }
}
</style>
