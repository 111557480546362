import api from './index'
import qs from 'qs'

const resource = '/sis_imports'

export default {
  getImports (query) {
    let url = ''
    const params = qs.stringify(query)
    url = resource + '?' + params
    return api.get(url, query)
  },
  initializeStructure (query = null) {
    let url = resource
    url += '/initialize_structure'
    const params = qs.stringify(query, { arrayFormat: 'brackets' })
    url += '?' + params
    return api.get(url)
  },
  initializeImport (query = null) {
    let url = resource
    url += '/initialize_import'
    const params = qs.stringify(query, { arrayFormat: 'brackets' })
    url += '?' + params
    return api.get(url)
  },
  getImportsInCsv (ids, type, filters, pagination) {
    const url = resource + `/import_in_csv?type=${type}`
    const body = {
      imports: ids,
      filters: filters,
      page: pagination.page
    }
    return api.post(url, body)
  },
  getImportJson(id) {
    let url = resource
    url += '/get_import_json?id=' + id
    return api.get(url)
  },
  getImportJsonFromSisImport(id) {
    let url = resource
    url += '/get_import_json_from_sis_import?id=' + id
    return api.get(url)
  },
  exportToPolon (id) {
    let url = resource + '/export_to_polon?id=' + id
    return api.get(url)
  },
  repeatImport (id) {
    let url = resource + '/repeat_import?id=' + id
    return api.get(url)
  },
  currentSisImportId () {
    let url = resource + '/current_sis_import_id'
    return api.get(url)
  },
  currentSisImportStatus (id) {
    let url = resource + '/current_sis_import_status?id=' + id
    return api.get(url)
  },
  cancelSisImport (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  }
}