export const general = {
  accept: 'Accept',
  accuracy: 'Accuracy',
  action_cannot_be_reverted: 'The action cannot be reversed',
  active: 'active',
  add: 'Add',
  add_attachment: 'Add attachment',
  all: 'All',
  and: 'and',
  archive: 'Archive',
  are_you_sure: 'Are you sure?',
  authors: 'Authors',
  avatar: 'Avatar',
  back: 'Back',
  calendar: 'Calendar',
  cancel: 'Cancel',
  change: 'Change',
  change_contrast: 'Change contrast',
  change_order: 'Change order',
  change_to: 'change to',
  check_errors: 'Check errors',
  clear: 'Clear',
  clear_institution: 'Clear institution data',
  clear_filters: 'Clear filters',
  collapse: 'Hide',
  confirm: 'Confirm',
  content: 'Content',
  copy: 'Copy',
  decrease_font: 'Smaller letters',
  delete: 'Delete',
  edit: 'Edit',
  edit_institution: 'Edit university details',
  edit_polon: 'Edit POL-on data',
  enlarge_font: 'Bigger letters',
  forbidden: 'No access.',
  lock: 'Lock',
  main_menu: 'Main menu',
  no: 'no',
  ok: 'OK',
  pagination_summary: '{from} - {to} of {total}',
  per_page: 'Results per page',
  roles: {
    employee: 'employee',
    systemadministrator: 'system administrator',
    systemmanager: 'system manager',
    unitadministrator: 'unit administrator'
  },
  select: 'Select',
  skip_to_content: 'Skip to content',
  unlock: 'Unlock',
  yes: 'yes'
}