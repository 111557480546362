<template>
  <base-beam>
    <ipo-beam mobile-active-class="justify-content-start" mobile-class="justify-content-between">
      <template>
        <span class="text-lowercase selected-elements ml-2 mr-4">
          {{ count }} {{ $t('views.imports.list.selected') }}
        </span>

        <span @click="cancelMultiCheckbox" class="cancel-checkbox">{{ $t('general.cancel') }}</span>
        <pcg-btn size="small" variant="additional" @click="exportCsv">{{ $t('views.imports.list.export_csv') }}</pcg-btn>
        <spinner v-if="spinnerAll" class="my-spinner"></spinner>
      </template>

      <template #mobile_beam>
        <div class="ml-4">
          {{ count }}
          <span class="text-lowercase mr-4">
            {{ $t('views.imports.list.selected') }}
          </span>
        </div>
      </template>

      <template #options>
        <span @click="cancelMultiCheckbox" class="cancel-checkbox">{{ $t('general.cancel') }}</span>
        <pcg-btn size="small" variant="additional" @click="exportCsv">{{ $t('views.imports.list.export_csv') }}</pcg-btn>
        <spinner v-if="spinnerAll" class="my-spinner"></spinner>
      </template>
    </ipo-beam>
  </base-beam>
</template>

<script>
import IpoBeam from './IpoBeam'
import BaseBeam from './beams/BaseBeam'
import Spinner from './Spinner'
import translateDictionary from '../mixins/translate_dictionary'

export default {
  name: 'DnBeamAchievementsList',
  mixins: [translateDictionary],
  components: {
    BaseBeam,
    IpoBeam,
    Spinner
  },
  data: function () {
    return {
      removeIcon: {
        type: 'component',
        value: 'icon-remove',
        width: '11',
        height: '13',
        color: '#00B4EB',
        hoverColor: '#a90000'
      },
      pcgOptions: [],
      status: null,
      statuses: [],
      currentUser: null,
      evaluation: true,
      params: {
        username: null,
        password: null,
        institution: null
      }
    }
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    spinnerAll: Boolean,
    filterStatus: String,
    filterPublicationType: String
  },
  computed: {
    additionalOptions () {
      return this.pcgOptions.slice(3)
    }
  },
  watch: {
    filterStatus () {
      this.initializeOptions()
    },
    filterPublicationType () {
      this.initializeOptions()
    },
    statuses () {
      this.initializeOptions()
    }
  },
  methods: {
    cancelMultiCheckbox () {
      this.$emit('cancelMultiCheckbox')
    },
    exportCsv () {
      this.$emit('exportCsv')
    }
  } 
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';

  .my-spinner {
    color: $main-active-color
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .cancel-checkbox {
    color: $main-active-color;
    font-weight: lighter;
    font-size: $font-size-s;
    margin-right: .5rem;
    cursor: pointer;

    &:hover {
      color: $hover-color;
    }
  }

  .selected-elements {
    color: $pcg-black;
  }

  .my-label {
    color: $pcg-gray;
    font-weight: 400;
  }

  .available-in {
    font-size: 1em;
    color: $pcg-gray;
    font-weight: 400;
    margin-right: 1.5rem;
    display: inline;
    float: left;
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    width: 200px;
    position: relative;
    display: inline-block;
    :deep(.bootstrap-select .dropdown-toggle){
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    :deep(.filter-option-inner) {
      position: relative;
      bottom: .45rem;
    }
  }

  .live-cable {
    color: $main-active-color;
    margin-left: .5rem;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .my-spinner {
      color: $main-active-color
    }
    .confirm-header {
      color: $pcg-gray;
    }

    .confirm-text {
      color: $pcg-gray;
    }

    .cancel-checkbox {
      color: $main-active-color;

      &:hover {
        color: $hover-color;
      }
    }
    .selected-elements {
      color: $pcg-black;
    }
    .my-label {
      color: $pcg-gray;
    }
    .available-in {
      color: $pcg-gray;
    }
    .my-select-short {
      :deep(.bootstrap-select .dropdown-toggle) {
        color: $pcg-gray;
      }
    }
    .live-cable {
      color: $main-active-color;
    }
  }
</style>
