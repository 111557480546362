import Vue from 'vue'
import VueRouter from 'vue-router'
import { app } from '../index'
import { store } from '../store'
import { i18n } from '../translations'
import qs from 'qs'

import Login from '../views/login/Login'
import PageNotFound from '../views/PageNotFound'
import EmptyView from '../views/EmptyView'
import RedirectView from '../views/RedirectView'
import UsersList from '../views/users/UsersList'
import InstitutionsList from '../views/institutions/InstitutionsList'
import ImportsList from '../views/imports/ImportsList'
import StudentsList from '../views/students/StudentsList'
import DevSessionsIndex from '../views/dev_sessions/DevSessionsIndex'
import IntegrationToken from '../views/integrations/IntegrationToken'
import IntegrationPolon from '../views/integrations/IntegrationPolon'
import NoSubscriptionView from '../views/NoSubscriptionView'
import ImportInitializationView from '../views/imports/ImportInitializationView.vue'
import SisImportsList from '../views/imports/SisImportsList.vue'
import IntegrationSis from '../views/integrations/IntegrationSis.vue'

Vue.use(VueRouter)

function subDomain () {
  const s = document.querySelector('#app-loader').dataset.subDomain
  const sd = s.length > 0 ? s : null
  return sd
}

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'link-active',
  linkExactActiveClass: 'exact-active',
  routes: [
    {
      path: '/',
      name: 'root_path',
      component: Login
    },
    {
      path: '/login',
      name: 'login_path',
      component: Login
    },
    {
      path: '/openid-connect',
      name: 'openid_login_path',
      component: Login
    },
    {
      path: '/no_rights',
      name: 'no_rights_path',
      component: EmptyView
    },
    {
      path: '/no_subscription',
      name: 'no_subscription_path',
      component: NoSubscriptionView
    },
    {
      path: '/redirect',
      name: 'redirect_path',
      component: RedirectView
    },
    {
      path: '/dev_login',
      name: 'dev_sessions_path',
      component: DevSessionsIndex
    },
    {
      path: '/users',
      component: UsersList,
      name: 'users_list_path',
      meta: (router) => ({ auth: true, roles: ['systemadministrator', 'unitadministrator'], allowed: true })
    },
    {
      path: '/institutions',
      name: 'institutions_list_path',
      component: InstitutionsList,
      meta: (router) => ({ auth: true, roles: ['systemadministrator', 'systemmanager'], allowed: (subDomain() === 'global') })
    },
    {
      path: '/imports',
      name: 'imports_list_path',
      component: ImportsList,
      meta: (router) => ({auth: true, roles: ['unitadministrator', 'employee'], allowed: (subDomain() !== 'global')})
    },
    {
      path: '/students',
      name: 'students_list_path',
      component: StudentsList,
      meta: (router) => ({auth: true, roles: ['unitadministrator', 'employee'], allowed: (subDomain() !== 'global')})
    },
    {
      path: '/token',
      name: 'token_path',
      component: IntegrationToken,
      meta: (router) => ({auth: true, roles: ['unitadministrator'], allowed: (subDomain() !== 'global')})
    },
    {
      path: '/polon',
      name: 'polon_path',
      component: IntegrationPolon,
      meta: (router) => ({auth: true, roles: ['unitadministrator'], allowed: (subDomain() !== 'global')})
    },
    {
      path: '/token',
      name: 'token_path',
      component: IntegrationToken,
      meta: (router) => ({auth: true, roles: ['unitadministrator'], allowed: (subDomain() !== 'global')})
    },
    {
      path: '/polon',
      name: 'polon_path',
      component: IntegrationPolon,
      meta: (router) => ({auth: true, roles: ['unitadministrator'], allowed: (subDomain() !== 'global')})
    },
    {
      path: '/import_initialization',
      name: 'import_initialization_path',
      component: ImportInitializationView,
      meta: (router) => ({auth: true, roles: ['unitadministrator', 'employee'], allowed: (subDomain() !== 'global')})
    },
    {
      path: '/sis_imports',
      name: 'sis_imports_list_path',
      component: SisImportsList,
      meta: (router) => ({auth: true, roles: ['unitadministrator', 'employee'], allowed: (subDomain() !== 'global')})
    },
    {
      path: '/sis_credentials',
      name: 'sis_credentials_path',
      component: IntegrationSis,
      meta: (router) => ({auth: true, roles: ['unitadministrator'], allowed: (subDomain() !== 'global')})
    },
    {
      path: '*',
      name: 'page_not_found_path',
      component: PageNotFound
    }
  ],
  parseQuery (query) {
    return qs.parse(query)
  },
  stringifyQuery (query) {
    const result = qs.stringify(query, { arrayFormat: 'brackets' })
    return result ? ('?' + result) : ''
  }
})

router.beforeEach((to, from, next) => {
  const meta = (typeof to.meta === 'function') ? to.meta() : to.meta

  if (store.getters['auth/signedIn'] && store.getters['auth/expire'] && !['dev_sessions_path', 'login_path', 'openid_login_path', 'root_path', 'no_rights_path', 'redirect_path', 'no_subscription_path'].includes(to.name)) {
    // token stracił ważność
    store.dispatch('auth/signOut').then((result) => {
      return next({ name: 'login_path' })
    })
  }

  store.dispatch('auth/updateState')
  store.dispatch('page/setContrast')
  if (meta.allowed === false || meta.block) {
    return next(false)
  }
  if (to.name !== from.name) {
    store.dispatch('header/clearHeader')
    store.dispatch('pageCover/clearBackgroundImage')
    store.dispatch('pageCover/clearBackgroundImageStyle')
    store.dispatch('pageCover/clearBackgroundImageClasses')
    store.dispatch('sidebarMenu/clearSidebarMenuClasses')
  }

  if (to && (meta.env || meta.localLogin)) {
    // eslint-disable-next-line eqeqeq
    if (process.env.RAILS_ENV === meta.env || process.env.LOCAL_LOGIN == meta.localLogin) {
      return next()
    } else if (to.name === 'no_rights_path' || to.name === 'redirect_path' || to.name === 'no_subscription_path') {
      return next()
    } else {
      return next({ name: 'login_path' })
    }
  }

  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters['auth/signedIn']) {
      if (to.meta.roles) {
        const userRole = store.getters['auth/currentRole']
        if (to.meta.roles.includes(userRole) || meta.allowed) {
          next()
        } else {
          if (Vue.$cookies.get('redirect_url')) {
            let url = window.location.origin
            url += Vue.$cookies.get('redirect_url')
            Vue.$cookies.remove('redirect_url')
            setTimeout(() => {
              window.location = url
            }, 2000)
          } else {
            if (['systemadministrator', 'systemmanager'].includes(userRole)) {
              next({ name: 'institutions_list_path' })
            } else if (['unitadministrator', 'employee'].includes(userRole)) {
              next({ name: 'imports_list_path' })
            } else {
              next ()
            }
          }
        }
      } else {
        next()
      }
    } else {
      const redirectTo = {
        name: name, params: to.params, query: to.query
      }
      store.dispatch('auth/setRedirectTo', redirectTo)
      next({ name: 'login_path' })
    }
  } else {
    const userRole = store.getters['auth/currentRole']
    if (userRole) {
      if (Vue.$cookies.get('redirect_url')) {
        let url = window.location.origin
        url += Vue.$cookies.get('redirect_url')
        Vue.$cookies.remove('redirect_url')
        setTimeout(() => {
          window.location = url
        }, 2000)
      }

      if (store.getters['auth/signedIn'] && ['login_path', 'root_path'].includes(to.name)) {
        if (['systemadministrator', 'systemmanager'].includes(userRole)) {
          next({ name: 'institutions_list_path' })
        } else if (['unitadministrator', 'employee'].includes(userRole)) {
          setTimeout(() => {
            next({ name: 'imports_list_path' })
          },2000)
        } else {
          next ()
        }
      } else {
        next()
      }
    } else {
      // dev_seesions_path, accessibility_declaration_path, no_rights_path i wszystkie kolejne strony na które można dostać się
      // nie będąc zalogowanym trzeba dodać do wyjątków w app.vue w checkForNetworkError
      if (['login_path', 'dev_sessions_path', 'root_path', 'no_rights_path', 'no_subscription_path'].includes(to.name)) {
        next()
      } else {
        next({name: 'no_rights_path' })
      }
    }
  }
})
export default router
