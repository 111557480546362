export default {
  methods: {
    downloadJson(data, name) {
      var el = document.createElement('a');
      document.body.appendChild(el);

      var data_string = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data, undefined, 2));
      el.setAttribute('href', data_string);
      el.setAttribute('download', `${name}.json`);
      el.click();
      el.remove();
    }
  }
}