<template>
  <div >
    <label v-if="showLabel" class="pcg-input-label">{{ label }}</label>
    <div :class="{'multi-disabled': options.length === 0}">
      <multiselect v-model="selectedOptions"
                   ref="multiselectRef"
                   :options="options"
                   :track-by="trackBy"
                   :label="selectLabel"
                   multiple
                   :disabled="options.length === 0"
                   :close-on-select="false"
                   :placeholder="placeholderText"
                   :select-label="selectLabelText"
                   :deselect-label="deselectLabelText"
                   :selected-label="selectedLabelText"
                   :search-placeholder="searchPlaceholderText"
      >
        <template slot="selection" slot-scope="{ values, search, isOpen }">
          <span class="multiselect__single" v-if="options.length > 0 && values.length" v-show="!isOpen">{{ $tc('components.pcg_multi_select.number_of_options', values.length) }}</span>
        </template>
      </multiselect>
    </div>
    <div v-if="options.length > 0 && selectedOptions.length > 0" class="chosen-options">
      Wybrane:
      <span v-for="(option, index) in selectedOptions" :key="index">
        <template v-if="index === (selectedOptions.length - 1)">
          {{ option.name }}
        </template>
        <template v-else>
          {{ option.name }},
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'PcgMultiSelect',
  components: { Multiselect },
  props: {
    value: Array,
    options: Array,
    label: String,
    trackBy: String,
    selectLabel: String,
    showLabel: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      placeholderText: 'Wybierz',
      selectLabelText: 'Wybierz',
      deselectLabelText: 'Odznacz',
      selectedLabelText: 'Wybrane',
      searchPlaceholderText: 'Szukaj...'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.multiselectRef.$el.addEventListener('input', this.handleInput)
    })
  },
  beforeDestroy() {
    this.$refs.multiselectRef.$el.removeEventListener('input', this.handleInput)
  },
  watch: {
    options: {
      deep: true,
      handler () {
        if (this.options.length === 0) {
          this.placeholderText = 'Brak opcji'
        } else {
          this.placeholderText = 'Wybierz'
        }
      }
    },
  },
  computed: {
    selectedOptions: {
      get() {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    handleInput (event) {
      if (event.target === this.$refs.multiselectRef.$el) {
        this.$emit('input', this.selectedOptions)
      }
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>

<style lang="scss">
@import '../assets/stylesheets/vars';

.chosen-options {
  font-size: .86em;
  color: $pcg-gray;
  font-weight: 500;
  margin-top: .5rem;
}

.multiselect {
  font-size: $font-size-s !important;
  color: $pcg-gray !important;
  font-weight: 400 !important;
}

.multiselect__select {
  font-size: $font-size-s;
  color: $main-active-color;
  font-weight: bold;
}

.multiselect__tags {
  border-radius: 25px;
  min-height: 40px;
  height: 40px;
  box-shadow: $select-box-shadow;
  padding: 8px 40px 0 12px;
  color: $main-active-color;
  font-weight: bold;
  background: $pcg-white;
}

.multiselect__input {
  color: $pcg-gray;
  font-size: $font-size-s;
  font-weight: 400;
  top: 2px;
  background: $pcg-white;
}

.multiselect__input::placeholder {
  color: $main-active-color;
  font-weight: bold;
  top: 2px;
}

.multiselect__placeholder {
  color: $main-active-color;
  font-size: $font-size-s;
  font-weight: bold;
  padding: 3px 40px 0 5px;
}

.multiselect__single {
  color: $main-active-color;
  font-size: $font-size-s;
  font-weight: bold;
  padding: 2px 40px 0 5px;
  background: $pcg-white;
}

.multiselect__content-wrapper {
  background: $pcg-white;
  box-shadow: $select-box-shadow;
  width: 135%;
  margin-top: .2rem;
  border-radius: 5px;
}

.multiselect__option--selected {
  color: $main-active-color;
  background: #f3f3f3;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: $pcg-white;
}

.multiselect__option--highlight {
  background: $main-color;
  color: $pcg-white;
  &::after{
    background: $main-color;
    color: $pcg-white;
  }
}


.album-number {
  color: $pcg-gray;
  height: 40px;
  box-shadow: $select-box-shadow;
  border: none;
  border-radius: 25px;
  width: 50%;
  font-size: $font-size-s;
  padding: 0 15px;
}

.multi-disabled {
  cursor: not-allowed;
}

.multiselect--disabled {
  pointer-events: none;
  background: none;
  .multiselect__select {
    background: none;
  }
}
</style>

<style lang="scss">
@import "../assets/stylesheets/vars-contrast";
.contrast {

  .chosen-options {
    color: $pcg-gray;
  }
  .multiselect {
    color: $pcg-gray !important;
  }

  .multiselect__select {
    color: $main-active-color;
  }

  .multiselect__tags {
    color: $main-active-color;
    box-shadow: $select-box-shadow;
    background: $pcg-white;
  }

  .multiselect__input {
    color: $pcg-gray;
    background: $pcg-white;
  }

  .multiselect__input::placeholder {
    color: $main-active-color;
  }

  .multiselect__placeholder {
    color: $main-active-color;
  }

  .multiselect__single {
    color: $main-active-color;
    background: $pcg-white;
  }

  .multiselect__content-wrapper {
    background: $pcg-white;
    box-shadow: $select-box-shadow;
  }

  .multiselect__option--selected {
    color: $main-active-color;
    background: #0c0c0c;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background: #ff6a6a !important;
    color: $pcg-black;
  }

  .multiselect__option--highlight {
    background: $main-color;
    color: $pcg-white;
    &::after{
      background: $main-color;
      color: $pcg-white;
    }
  }
}
</style>
