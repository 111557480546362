// explanation why not use this._uid
// https://github.com/vuejs/vue/issues/5886#issuecomment-308625735
export default function (root = null) {
  function uuidv4 () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      function (c) {
        const r = Math.random() * 16 | 0
        const v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
  }

  return {
    uuidv4
  }
}
