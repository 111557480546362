<template>
  <div style="margin-top: 3rem;">
    <ipo-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
        <div v-if="token">
          <pcg-text-input
              id="fullname"
            class="my-text-input"
            style="margin-right: 3rem"
            :show-label="false"
            placeholder="***********************"
            v-model="showToken"
          />
          <div class='row'>
            <pcg-btn size="small" variant="additional" @click="generateToken()" >{{ $t('views.integrations.generate_token') }}</pcg-btn>
            <pcg-btn size="small" @click="displayToken()">{{ $t('views.integrations.show_token') }}</pcg-btn>
          </div>
        </div>
        <div v-else>
          <p class="no-token">{{ $t('views.integrations.no_token') }}</p>
          <pcg-btn size="small"  @click="generateToken()">{{ $t('views.integrations.generate_token') }}</pcg-btn>
        </div>
    </div>
  </div>
</template>

<script>
import ApiInstitutions from '../../api/institutions' 
import IpoLoader from '../../components/IpoLoader'
export default {
  name: 'IntegrationToken',
  components: {
    IpoLoader
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.integrations.token'),
    })
  },
  data: function () {
    return {
      token: null,
      showToken: null,
      showLoader: true
    } 
  },
  created () {
    ApiInstitutions.getIntegrationToken()
    .then(result => {
      if (result.data.integrationToken) {
        this.token = result.data.integrationToken
      }
    })
    this.showLoader = false
  },
  methods: {
    generateToken (){
      this.showLoader = true
      this.showToken = null
      ApiInstitutions.refreshIntegrationToken()
      .then(result => {
        if (result.data.integrationToken) {
          this.token = result.data.integrationToken
        }
      })
      this.showLoader = false
    },
    displayToken(){
      this.showToken = this.token
    }

  }
}

</script>

<style scoped lang="scss">
@import '../../assets/stylesheets/vars';

.no-token {
  color: $pcg-dark-gray;
  font-size: $font-size-m;
  font-weight: 400;
}

</style>

<style scoped lang='scss'>
@import "../../assets/stylesheets/vars-contrast";

.contrast {
  .no-token {
    color: $pcg-dark-gray;
  }
}
</style>