<template>
  <pre v-html="prettyJSON(json)"
      class="json-prettify"
  >
  </pre>
</template>


<script>

export default {
  name: 'JsonPrettify',
  props: {
    json: {
      required: true,
      type: Object
    }
  },
  methods: {
    prettyJSON (val) {
      let json = JSON.stringify(val, undefined, 4)
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
          var cls = "number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key";
            } else {
              cls = "string";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean";
          } else if (/null/.test(match)) {
            cls = "null";
          }
          return '<span class="' + cls + '">' + match + "</span>";
        }
      )
    }
  }
}
</script>

<style lang="scss">
  pre.json-prettify {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 15px;
    background: #1E1E1E;
    color: white;
    max-height: 31rem;
    overflow: auto;
    
    .string {
      color: #B3806A;
    }
    .number {
      color: #9bddc1;
    }
    .boolean {
      color: #446379;
    }
    .null, .undefined {
      color: #446379;
    }
    .key {
      color: #7AA9C1;
    }
  }
</style>