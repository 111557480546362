<template>
  <div class="d-flex align-items-center import-status">
    <div v-if="status == 0" class="import-status__container">
      <i class="fas fa-check green-color small">&nbsp;</i>
      <span>{{ $t('views.students.list.status.exported') }}</span>
    </div>
    <div v-else-if="status == 1" class="import-status__container">
      <i class="fas fa-exclamation orange-color small">&nbsp;</i>
      <span>{{ $t('views.students.list.status.partially_exported') }}</span>
    </div>
    <div v-else-if="status == 2" class="import-status__container">
      <i class="fas fa-times red-color small">&nbsp;</i>
      <span>{{ $t('views.students.list.status.failed') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'student-status',
  props: {
    className: {
      type: String,
      default: ''
    },
    status: {
      required: true,
      type: Number
    }
  }
}
</script>

<style scoped lang="scss">

@import '../assets/stylesheets/vars.scss';
.import-status{
  .status-spinner span{
    color: #7ED376;
  }
  &__container, .status-spinner{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding-left: 0;
    i{
      height: 28px;
      width: 28px;
      min-width: 28px;
      border-radius: 50%;
      position: relative;
      &::before{
        width: 20px;
        height: 20px;
        position: absolute;
      }
    }
    .red-color {
      background-color: #FDC7C8;
      color: #FA4C4D;
       & + span{
        color: #FA4C4D;
      }
      &::before{
        left: 10px;
        top: 8px;
      }
    }
    .orange-color {
      background-color: #EFC86B;
      color: $pcg-white;
       & + span{
        color: #EFC86B;
      }
      &::before{
        left: 12px;
        top: 9px;
      }
    }
    .green-color {
      background-color: #CCEEC8;
      color: #7ED376;
      & + span{
        color: #7ED376;
      }
      &::before{
        left: 8px;
        top: 8px;
      }
    }
  }
}

</style>