import api from './index'

const resource = 'institutions'

export default {
  getInstitutions () {
    const url = resource
    return api.get(url)
  },
  createInstitution (institution) {
    const url = resource
    const body = {
      institution: institution
    }
    return api.post(url, body)
  },
  fillInstitution (institution, latest) {
    let url = resource
    url += '/' + institution.id + '/fill'
    const body = {
      institution: institution,
      latest: latest
    }
    return api.put(url, body)
  },
  updateInstitution (institution) {
    let url = resource
    url += '/' + institution.id
    const body = {
      institution: institution
    }
    return api.put(url, body)
  },
  clearInstitution (institution) {
    let url = resource
    url += '/' + institution.id + '/clear'
    return api.get(url)
  },
  destroyInstitution (institution) {
    let url = resource
    url += '/' + institution.id + '/destroy_institution'
    return api.delete(url)
  },
  lockInstitution (institution) {
    let url = resource
    url += '/' + institution.id + '/lock'
    return api.post(url)
  },
  restartApp () {
    const url = resource + '/restart_app'
    return api.get(url)
  },
  goInto (institution) {
    let url = 'users'
    url += '/into?institution=' + institution.id
    return api.get(url)
  },
  getIntegrationToken() {
    let url = resource
    url += '/get_integration_token'
    return api.get(url)
  },
  refreshIntegrationToken() {
    let url = resource
    url += '/generate_integration_token'
    return api.get(url)
  },
  updatePolonCredentials(data) {
    const body = {
      credentials: {
        polon_login: data.polonLogin,
        polon_password: data.polonPassword,
        polon_institution_id: data.polonInstitutionId,
        configuration: data.configuration,
        institution_id: data.institutionId
      }
    }
    let url = resource
    url += '/update_polon_credentials'
    return api.post(url, body)
  },
  getPolonCredentials(data) {
    let url = resource
    url += '/get_polon_credentials'
    return api.get(url)
  },
  getInstitutionInformation () {
    const url = resource + '/get_institution_information'
    return api.get(url)
  },
}