import api from './index'
import qs from 'qs'

const resource = '/imports'

export default {
  getImports (query) {
    let url = ''
    const params = qs.stringify(query)
    url = resource + '?' + params
    return api.get(url, query)
  },
  updateImport (id, roles, facultyId) {
    let url = resource
    url += '/' + id + '/update_roles'
    const body = {
      id: id,
      faculty_id: facultyId,
      roles: roles
    }
    return api.put(url, body)
  },
  exportImportErrors(query){
    let url = resource
    url += '/export_errors_in_xls'
    return api.post(url, query)
  },
  createImport (user) {
    return api.post(resource, user)
  },
  repeatImport (id) {
    let url = resource + '/repeat_import?import_id=' + id
    return api.get(url)
  },
  getImportErrors (id) {
    let url = resource + '/import_errors?import_id=' + id
    return api.get(url)
  },
  getImportsInCsv (ids, type, filters, pagination) {
    const url = resource + `/import_in_csv?type=${type}`
    const body = {
      imports: ids,
      filters: filters,
      page: pagination.page
    }
    return api.post(url, body)
  },
  getImportJson(id) {
    let url = resource
    url += '/get_import_json?id=' + id
    return api.get(url)
  },
  currentImportId () {
    let url = resource + '/current_import_id'
    return api.get(url)
  }
}