import api from './index'
import qs from 'qs'

const resource = '/students'

export default {
  getStudents (query) {
    let url = ''
    const params = qs.stringify(query)
    url = resource + '?' + params
    return api.get(url, query)
  },
  initStudentHistory (student_id) {
    let url = resource
    url += '/show_history_init?id=' + student_id 
    return api.get(url)
  },
  getStudentHistory (job_id) {
    let url = resource
    url += '/show_history?job_id=' + job_id
    return api.get(url)
  },
  getStudentJson(id) {
    let url = resource
    url += '/show_student_json?id=' + id
    return api.get(url)
  },
  getStudentPolonJson(id) {
    let url = resource
    url += '/show_student_polon_json?id=' + id
    return api.get(url)
  }
}
