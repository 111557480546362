import Vue from 'vue'
import Vuex from 'vuex'

import { locale } from 'pcg-core-vue/src/store/modules/locale'
import { menu } from 'pcg-core-vue/src/store/modules/menu'
import { header } from 'pcg-core-vue/src/store/modules/header'
import { pageCover } from 'pcg-core-vue/src/store/modules/pageCover'

import { auth } from './modules/auth'
import { user } from './modules/user'
import { page } from './modules/page'
import { sidebarMenu } from './modules/sidebarMenu'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    user,
    page,
    sidebarMenu,
    locale,
    menu,
    header,
    pageCover
  }
})

export {
  store
}
