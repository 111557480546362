<template>
  <div class="history-element" :class="allStudentsErrorsFromImport ? 'no-margin-top' : ''">
    <b-row v-if="allStudentsErrorsFromImport === false">
      <b-col>
        <div class="element-student">
          <div class='student'>
            <span class="author-name">Kurs numer {{courseIndex}} </span>
          </div>
        </div>
      </b-col>
      <b-col class="text-center col-1">
        <div class="my-hide" v-on:click="detailsVisible = !detailsVisible">
          <i v-if="detailsVisible" class="fas fa-angle-up"></i>
          <i v-else class="fas fa-angle-down"></i>
        </div>
      </b-col>
    </b-row>
    <template v-if="allStudentsErrorsFromImport === false">
      <b-collapse id="filters-collapse" v-model="detailsVisible">
        <div class="element-changes student-history" v-if='Array.isArray(history) && history.length > 0'>
          <div class="student-history__details--item"
            v-for='(error, index) in history' :key='index'
          > 
            <div>
              <div class='history-item__changes'>
                <div class='history-item__changes--before'>
                  <span v-if="error.key">
                    {{ error.key }}: {{ error.content }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="element-changes student-history" v-else-if='typeof history === "object"'>
          <div class="student-history__details--item"> 
            <div>
              <div class='history-item__changes'>
                <div class='history-item__changes--before'>
                  <span v-html="history.content">
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="element-changes student-history" v-else>
          <div class="student-history__details--item"> 
            <div>
              <div class='history-item__changes'>
                <div class='history-item__changes--after'>
                  <span>  Brak błędów </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </template>
    <template v-else>
    <b-row id="filters-collapse">
      <div class="element-changes student-history">
        <div class="student-history__details--item"> 
          <div>
            <div class='history-item__changes'>
              <div class='history-item__changes--before'>
                <span v-html="history.content">  
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    </template>
    <hr/>
  </div>
</template>

<script>
export default {
  name: "StudentErrorElement",
  data: function () {
    return {
      detailsVisible: true,
    }
  },
  props: {
    history: [Array, Object],
    courseIndex: Number,
    allStudentsErrorsFromImport: Boolean, default: false
  }
}
</script>

<style scoped lang='scss'>
@import '../assets/stylesheets/vars.scss';

.student-history{
  display: flex;
  flex-direction: column;
  :deep(.btn-light){
    width: 100%;
    background: transparent !important;
    border: transparent;
    color: grey !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0px;
    @media (max-width: 500px){
      height: 5rem !important;
    }
    &:hover{
      background: transparent;
      border: transparent;
      color: grey;
    }
    &::after{
      color: grey;
      margin-top: 5px;
    }
  }
  &__info{
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 500px){
      flex-direction: column;
    }
    span{
      color: $main-active-color;
      font-weight: bold;
    }
  }
  .student-history{
    &__details--item{
      .history-item__title{
        margin-top: 1rem;
      }
      .history-item__changes{
        & > div{
          border-radius: 3px;
          padding: 0.5rem 1rem;
          margin: 4px 0;
          display: flex;
          justify-content: space-between;
          @media (max-width: 500px){
            flex-direction: column;
          }
        }
        &--before{
          background-color: #FDC7C8;
          color: #FA4C4D;
        }
        &--after{
          background-color: #CCEEC8;
          color: #7ED376;
        }
      }
    }
  }
  :deep(.student-history__button){
    display: block !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
</style>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .history-elements:not(.no-margin-top) {
    margin-top: 2rem;
  }
  .element-student {
    display: flex;
    justify-content: space-between;

    .student {
      .author-name {
        font-weight: bold;
        color: $main-active-color;
      }

      .changes-text {
        color: $pcg-dark-gray;
      }
    }

    .date {
      color: $pcg-gray;
      font-weight: 400;
    }
  }
  .element-changes {
    margin-top: 1.5rem;
    .key {
      color: $pcg-dark-gray;
      margin-bottom: .2rem;
    }
    .new-value {
      color: $alert-success;
      background-color: lighten($alert-success, 35%);
      margin-bottom: .5rem;
      border-radius: 5px;
      padding: .5rem 1rem;
      font-weight: 400;
    }
    .old-value {
      color: $alert-error;
      background-color: lighten($alert-error, 25%);
      border-radius: 5px;
      padding: .5rem 1rem;
      font-weight: 400;
    }
  }

  .my-hide {
    cursor: pointer;
    color: $main-active-color;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .element-student {
      .student {
        .author-name {
          color: $main-active-color;
        }
        .changes-text {
          color: $pcg-dark-gray;
        }
      }
      .date {
        color: $pcg-gray;
      }
    }
    .element-changes {
      .key {
        color: $pcg-dark-gray;
      }
      .new-value {
        color: $alert-success;
        background-color: lighten($alert-success, 35%);
      }
      .old-value {
        color: $alert-error;
        background-color: lighten($alert-error, 25%);
      }
    }
    .my-hide {
      color: $main-active-color;
    }
  }
</style>