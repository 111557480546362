export const components = {
  navigation_menu: {
    exports: 'Eksporty',
    imports: 'Importy',
    import_initialization: 'Inicjalizacja importu',
    institutions: 'Instytucje',
    integrations: 'Integracje',
    log_in: 'Zaloguj',
    logout: 'Wyloguj z',
    polon: 'POL-on 2.0',
    sis_credentials: 'Dane konfiguracyjne SIS',
    students: 'Studenci',
    token: 'Token',
    users: 'Użytkownicy'
  },
  pcg_header_profile_button: {
    logout: 'Wyloguj',
    role_change_to: 'Zmień rolę na {role}'
  },
  pcg_multi_select: {
    number_of_options: ' | {count} opcję wybrano | {count} opcje wybrano | {count} opcji wybrano',
  },
  pcg_pagination_summary: {
    summary: '{from} - {to} z {total}'
  },
  ipo_field: {
    add: '+ Dodaj',
    show_students: "Zobacz listę studentów",
    download_json: "Pobierz plik JSON",
    show_details: 'Pokaż szczegóły',
    show_json: 'Pokaż JSON',
    show_polon_json: 'Pokaż JSON do POL-on 2.0',
    export: "Eksportuj",
    export_cancel: 'Eksportuj/Anuluj',
    show_errors: "Pokaż błąd",
    export_to_xlsx: "Eksportuj błędy do pliku xlsx"
  }
}