<template>
    <div class="academy row">
        <b-col>
            <span class="name" style="margin-right: .3rem">{{institution.name}}</span>
            <span class="clicked" @click="goInto" :id="`institution-go-into` + institution.id"><i class="fas fa-sign-in-alt academy-icon"></i></span>
            <b-tooltip :target="`institution-go-into` + institution.id">
              {{ $t('views.institutions.list.go_into_academy') }}
            </b-tooltip>
        </b-col>
        <b-col>
          <span class="name">{{institution.replicationDate ? $d(Date.parse(institution.replicationDate), 'long') : ''}}</span>
        </b-col>
        <b-col>
          <span v-if="applicationVersion() === 'Rozszerzona'" class="name-advanced">{{ applicationVersion() }}</span>
          <span v-else class="name">{{ applicationVersion() }}</span>
        </b-col>
        <b-col>
          <span class="name">{{ polonVersion() }}</span>
        </b-col>
        <b-col cols="1" class="pub-type">
          <span class="locked">{{ institution.locked ? $t('general.yes') : $t('general.no') }}</span>
        </b-col>
        <b-col cols="4" class="buttons d-flex justify-content-end">
          <spinner v-if="blockSpinner" class="buttons-icon" style="width: 38px; padding: 0 12px;"/>
          <span class="buttons-icon clicked button-element" @click="lockInstitution" v-if="!blockSpinner" :disabled="blockSpinner">
            <i class="fas fa-ban institution-icon" :id="`block-institution` + institution.id"></i>
            <b-tooltip :target="`block-institution` + institution.id">
              {{ institution.locked ? $t('general.unlock') : $t('general.lock') }}
            </b-tooltip>
          </span>
          <spinner v-if="editSpinner" class="buttons-icon" style="width: 38px; padding: 0 12px;"/>
          <span class="buttons-icon clicked button-element" @click="edit" v-if="!editSpinner" :disabled="editSpinner">
            <i class="fas fa-pen institution-icon" :id="`edit-institution` + institution.id"></i>
            <b-tooltip :target="`edit-institution` + institution.id">
              {{ $t('general.edit_institution') }}
            </b-tooltip>
          </span>
          <spinner v-if="editPolonSpinner" class="buttons-icon" style="width: 38px; padding: 0 12px;"/>
          <span class="buttons-icon clicked button-element" @click="editPolon" v-if="!editPolonSpinner" :disabled="editPolonSpinner">
            <i class="fas fa-edit institution-icon" :id="`edit-polon-institution` + institution.id"></i>
            <b-tooltip :target="`edit-polon-institution` + institution.id">
              {{ $t('general.edit_polon') }}
            </b-tooltip>
          </span>
          <spinner v-if="clearSpinner" class="text-danger buttons-icon" style="width: 38px; padding: 0 12px;"/>
          <span class="text-danger buttons-icon clicked button-element" @click="clearModal" v-if="!clearSpinner" :disabled="clearSpinner">
            <i class="fas fa-eraser institution-icon" :id="`clear-institution` + institution.id"></i>
            <b-tooltip :target="`clear-institution` + institution.id">
              {{ $t('general.clear_institution') }}
            </b-tooltip>
          </span>
          <spinner v-if="deleteSpinner" class="text-danger" style="width: 38px; padding: 0 12px;"/>
          <span class="text-danger clicked button-element" @click="deleteModal" v-if="!deleteSpinner" :disabled="deleteSpinner">
            <i class="fas fa-trash institution-icon" :id="`delete-institution` + institution.id"></i>
            <b-tooltip :target="`delete-institution` + institution.id">
              {{ $t('general.delete') }}
            </b-tooltip>
          </span>
        </b-col>
        <b-modal ref="institutionModal"
                 :hide-header="true"
                 :centered="true"
                 footer-class="my-modal-footer"
                 v-bind:ok-title="$t('general.confirm')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 @ok="institutionSave"
                 v-bind:cancel-title="$t('general.cancel')"
                 cancel-variant="default"
                 v-on-clickaway = "escModal"
                 content-class="my-modal-content"
        >
          <b-col class="type-checkbox">
            <pcg-radio
                name="installationVersion"
                v-model="rInstitution.installationVersion"
                :options="installationOptions"
                :show-label="true"
                :label="$t('views.institutions.list.fields.installation_version')"
            />
          </b-col>
          <pcg-text-input class="my-input" v-model="rInstitution.name" :label="$t('views.institutions.list.fields.name')" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rInstitution.managerEmail" :label="$t('views.institutions.list.fields.manager_email')" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rInstitution.tenantId" :label="$t('views.institutions.list.fields.identifier')" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rInstitution.secret" :label="$t('views.institutions.list.fields.secret')" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rInstitution.origin" :label="$t('views.institutions.list.fields.origin')" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rInstitution.issuer" :label="$t('views.institutions.list.fields.issuer')" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rInstitution.endSessionEndpoint" :label="$t('views.institutions.list.fields.end_session_endpoint')" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rInstitution.authorizationEndpoint" :label="$t('views.institutions.list.fields.authorization_endpoint')" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rInstitution.tokenEndpoint" :label="$t('views.institutions.list.fields.token_endpoint')" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rInstitution.userInfoEndpoint" :label="$t('views.institutions.list.fields.user_info_endpoint')" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rInstitution.scope" :label="$t('views.institutions.list.fields.scope')" :showLabel="true"/>
        </b-modal>
        <b-modal ref="clearModal"
                 :hide-header="true"
                 footer-class="my-modal-footer"
                 :centered="true"
                 v-bind:ok-title="$t('general.confirm')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 @ok="clearInstitution"
                 v-bind:cancel-title="$t('general.cancel')"
                 cancel-variant="default"
                 content-class="my-modal-content"
        >
          <div class="confirm-header">{{ $t('views.institutions.list.base_cleaning') }}</div>
          <div class="confirm-text">{{ $t('views.institutions.list.base_cleaning_confirmation') }}</div>
        </b-modal>
        <b-modal ref="deleteModal"
                 :hide-header="true"
                 footer-class="my-modal-footer"
                 :centered="true"
                 v-bind:ok-title="$t('general.confirm')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 @ok="deleteInstitution"
                 v-bind:cancel-title="$t('general.cancel')"
                 cancel-variant="default"
                 content-class="my-modal-content"
        >
            <div class="confirm-header">{{ $t('views.institutions.list.base_deleting') }}</div>
            <div class="confirm-text">{{ $t('views.institutions.list.base_deleting_confirmation') }}</div>
        </b-modal>
        <b-modal ref="credentialsForm"
                 :hide-header="true"
                 :centered="true"
                 footer-class="my-modal-footer"
                 v-bind:ok-title="$t('general.confirm')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 @ok="credentialsSave"
                 v-bind:cancel-title="$t('general.cancel')"
                 cancel-variant="default"
                 content-class="my-modal-content"
        >
          <div class="my-header">
            <span>{{ $t('views.integrations.integration_modal') }}</span>
          </div>
          <b-col class="type-checkbox">
            <pcg-radio
                name="polonConfiguration"
                v-model="institutionCredentials.configuration"
                :options="configurationOptions"
                :show-label="true"
                :label="$t('views.institutions.list.fields.config')"
            />
          </b-col>
        </b-modal>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import ApiInstitutions from '../api/institutions'
import Spinner from './Spinner'
import waitForJobs from '../mixins/wait_for_jobs'

export default {
  name: 'institution-element',
  components: { Spinner },
  mixins: [clickaway, waitForJobs],
  data: function () {
    return {
      blockSpinner: false,
      clearSpinner: false,
      deleteSpinner: false,
      editSpinner: false,
      editPolonSpinner: false,
      latest: true,
      updateSpinner: false,
      rInstitution: {
        aid: null,
        name: null,
        subDomain: null,
        tenantId: null,
        managerEmail: null,
        secret: null,
        origin: null,
        issuer: null,
        endSessionEndpoint: null,
        authorizationEndpoint: null,
        tokenEndpoint: null,
        userInfoEndpoint: null,
        scope: null,
        installationVersion: '0'
      },
      institutionCredentials: {
        polonLogin: null,
        polonPassword: null,
        polonInstitutionId: null,
        configuration: '0'
      },
      errors: {},
      configurationOptions: [
        {
          text: this.$t('views.institutions.list.configuration_types.demo'), value: '0'
        },
        {
          text: this.$t('views.institutions.list.configuration_types.prod'), value: '1'
        },
      ],
      installationOptions: [
        {
          text: this.$t('views.institutions.list.installation_versions.basic'), value: '0'
        },
        {
          text: this.$t('views.institutions.list.installation_versions.extended'), value: '1'
        },
      ]
    }
  },
  props: {
    institution: Object
  },
  mounted: function () {
    for (const key in this.institution) {
      this.rInstitution[key] = this.institution[key]
    }
  },
  computed: {
  },
  methods: {
    deleteInstitution () {
      this.deleteSpinner = true
      ApiInstitutions.destroyInstitution(this.institution)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.waitForJob(jobId, this.deletedInstitution, this.deletedInstitution)
          } else {
            this.deleteSpinner = false
          }
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
          this.deleteSpinner = false
        })
    },
    deletedInstitution () {
      ApiInstitutions.restartApp()
        .then(result => {
          this.deleteSpinner = false
          this.$toastr.s(this.$t('views.institutions.dropped'))
          setTimeout(() => {
            this.$router.go(0)
          }, 1000)
        })
        .catch(error => {
          const datas = error.response.data
          const message = []
          for (const key in datas) {
            message.push(`${key.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
          this.createSpinner = false
        })
    },
    deleteModal () {
      this.$refs.deleteModal.show()
    },
    clearInstitution () {
      this.clearSpinner = true
      ApiInstitutions.clearInstitution(this.institution)
          .then(result => {
            const jobId = result.data.jobId
            if (jobId !== undefined) {
              this.waitForJob(jobId, this.clearedInstitution, this.clearedInstitution)
            } else {
              this.clearSpinner = false
            }
          })
          .catch(error => {
            console.log(error)
            this.$toastr.e(this.$t('general.unexpected_error'))
            this.clearSpinner = false
          })
    },
    clearedInstitution () {
      this.$toastr.s(this.$t('views.institutions.cleared'))
      this.clearSpinner = false
    },
    clearModal () {
      this.$refs.clearModal.show()
    },
    edit () {
      for (const key in this.institution) {
        this.rInstitution[key] = this.institution[key]
      }
      this.$refs.institutionModal.show()
    },
    escModal () {
      this.$refs.institutionModal.hide()
    },
    editPolon () {
      for (const key in this.institution.institutionCredentials) {
        this.institutionCredentials[key] = this.institution.institutionCredentials[key]
      }
      this.$refs.credentialsForm.show()
    },
    goInto () {
      ApiInstitutions.goInto(this.institution)
        .then(response => {
          if (response) {
            if (response.data.location) {
              window.location = response.data.location
            }
          }
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    lockInstitution () {
      this.blockSpinner = true
      ApiInstitutions.lockInstitution(this.institution)
        .then(result => {
          setTimeout(() => {
            this.$router.go(0)
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
          this.blockSpinner = false
        })
    },
    institutionSave (e) {
      e.preventDefault()
      this.editSpinner = true
      ApiInstitutions.updateInstitution(this.rInstitution)
        .then(result => {
          this.$refs.institutionModal.hide()
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.waitForJob(jobId, this.updateInstitution, this.updateInstitution)
          } else {
            this.editSpinner = false
          }
        })
        .catch(err => {
          console.log(err)
          const datas = err.response.data
          const message = []
          for (const key in datas) {
            let val = key
            if (key.toString() == "base"){
              val = ""
            }
            message.push(`${val.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
          this.editSpinner = false
        })
    },
    updateInstitution () {
      ApiInstitutions.restartApp()
          .then(result => {
            this.$re
            this.updateSpinner = false
            this.editSpinner = false
            this.$toastr.s(this.$t('views.institutions.updated'))
            setTimeout(() => {
              this.$router.go(0)
            }, 1000)
          })
          .catch(error => {
            const datas = error.response.data
            const message = []
            for (const key in datas) {
              message.push(`${key.toString()} ${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
            this.updateSpinner = false
            this.editSpinner = false
          })
    },
    updatedInstitution () {
      this.$emit('updated')
      this.updateSpinner = false
      this.editSpinner = false
    },
    credentialsSave (e) {
      e.preventDefault()
      this.errors = {}
      ApiInstitutions.updatePolonCredentials(this.institutionCredentials)
          .then(result => {
            this.$refs.credentialsForm.hide()
            this.$emit('updated')
          })
          .catch(error => {
            console.log(error)
            this.$toastr.e(this.$t('views.integrations.polon_error'))
            this.errors = error.response.data
          })
    },
    polonVersion () {
      if (this.institution.institutionCredentials) {
        if (this.institution.institutionCredentials.configuration === '0') {
          return 'Demo'
        } else {
          return 'Produkcja'
        }
      }
    },
    applicationVersion () {
      if (this.institution.installationVersion) {
        if (this.institution.installationVersion === '0') {
          return 'Podstawowa'
        } else {
          return 'Rozszerzona'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../assets/stylesheets/vars';
    .name {
        color: $pcg-gray;
        font-weight: 400;
    }

    .name-advanced {
      color: $main-color;
      font-weight: bold;
    }

    .locked {
      color: $pcg-gray;
      font-weight: 400;
    }

    .my-input {
        :deep(.pcg-input) {
            height: calc(1.5rem + 0.75rem + 2px)
        }
        :deep(.pcg-input-lab)el {
            margin-bottom: 0.25rem;
            color: $pcg-gray;
        }
    }

    .clicked {
        cursor: pointer;
        font-weight: 400;
    }

    .confirm-header {
        font-size: $font-size-m;
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1.5rem;
    }

    .confirm-text {
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1rem;
    }

    .academy-icon {
      color: $main-active-color;
    }

    ::v-deep .tooltip-inner {
      background: $pcg-the-lightest-gray-background;
      color: $pcg-gray;
      font-weight: 400;
      padding: .5rem;
      border-color: $pcg-light-gray-background;
      border-style: solid;
      border-width: 1px;
      opacity: 1;
    }

    ::v-deep .arrow::before {
      border-top-color: $pcg-light-gray-background !important;
    }

    .buttons-icon {
      color: $main-active-color;
      margin-right: 2rem;
      @media (max-width: 1100px) {
        margin-right: 1rem;
      }
    }

    .text-active {
      color: $main-active-color;
    }

  .institution-icon {
    padding: 12px;
    border-radius: 50%;
    background-color: #ecf9fd;
  }

  .button-element {
    position: relative;
    bottom: .7rem;
  }

    .errors{
      color: red;
      font-size: 12px;
    }

    .my-header {
      margin-bottom: 2rem;
      font-size: $font-size-l;
      font-weight: bold;
      color: $main-color;
    }
    .type-checkbox {
      margin: 0 0 1rem 0;
      padding: 0;
    }
    .info {
      color: #606060;
      font-weight: bold;
      font-size: 1.14em;
      margin-bottom: 2rem;
    }
</style>

<style lang="scss" scoped>
    @import "../assets/stylesheets/vars-contrast";
    .contrast {
        .name {
            color: $pcg-gray;
        }
      .name-advanced {
        color: $main-color;
      }
      .locked {
        color: $pcg-gray;
      }
        .my-input {
            :deep(.pcg-input-label) {
                color: $pcg-gray;
            }
        }
        .confirm-header {
            color: $pcg-gray;
        }
        .confirm-text {
            color: $pcg-gray;
        }
      .academy-icon {
        color: $main-active-color;
      }
      :deep(.tooltip-inner) {
        background: $pcg-the-lightest-gray;
        color: $pcg-gray;
        border-color: $pcg-light-gray;
      }
      :deep(.arrow::before) {
        border-top-color: $pcg-light-gray !important;
      }
      .buttons-icon {
        color: $main-active-color;
      }
      .text-active {
        color: $main-active-color;
      }
      .institution-icon {
        background-color: #003204;
      }
      .my-header {
        color: $main-color;
      }
    }
</style>
