export default {
  methods: {
    downloadCsv(data, name) {
      var el = document.createElement('a');
      document.body.appendChild(el);

      var data_string = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
      el.setAttribute('href', data_string);
      el.setAttribute('download', `${name}.csv`);
      el.click();
      el.remove();
    }
  }
}