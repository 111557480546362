<template>
  <div>
    <div class="no-rights">{{ $t('views.empty_view.text') }}</div>
    <div style="text-align: center" class="return-login" @click="userLogout">
      {{ $t('views.empty_view.return') }}
    </div>
  </div>
</template>

<script>
import InstitutionsApi from '../api/institutions'
import supportedLanguages from "../translations/supported_langs";

export default {
  name: 'EmptyView',
  data: function () {
    return {
      endSessionEndpoint: null
    }
  },
  created () {
    InstitutionsApi.getInstitutionInformation()
        .then(response => {
          this.endSessionEndpoint = response.data.endSessionEndpoint
        })
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.empty_view.title'),
      subtitle: this.$t('views.empty_view.subtitle'),
      capitalizeSubtitle: false
    })
  },
  methods: {
    userLogout () {
      this.$store.dispatch('auth/signOut')
          .then(response => {
            // eslint-disable-next-line eqeqeq
            if (process.env.LOCAL_LOGIN == '1') {
              window.location = '/login'
            } else {
              if (this.signedIn) {
                if (this.$store.getters['page/subDomain'] === 'global') {
                  window.location = `${this.endSessionEndpoint}?post_logout_redirect_uri=https://www.${process.env.SERVER_ADDRESS}`
                } else {
                  window.location = `${this.endSessionEndpoint}?post_logout_redirect_uri=https://${this.$store.getters['page/subDomain']}.${process.env.SERVER_ADDRESS}`
                }
              } else {
                window.location = '/'
              }
            }
          })
          .catch(error => {
            console.log(error.message)
            this.$toastr.e(error.message)
          })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/stylesheets/vars';

.no-rights {
  color: $pcg-gray;
  font-weight: bold;
  font-size: $font-size-m;
  padding: 0 8rem;
  margin: 5rem 0;
}

.return-login {
  color: $main-active-color;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
