<template>
  <div>
    <pcg-btn size="small" @click="displayForm()">{{ $t('views.integrations.integration_data') }}</pcg-btn>
    <b-modal ref="credentialsForm"
             :hide-header="true"
             :centered="true"
             footer-class="my-modal-footer"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="credentialsSave"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             content-class="my-modal-content"
    >
      <div class="my-header">
        <span>{{ $t('views.integrations.sis_modal') }}</span>
      </div>
      <b-col v-if="errors.endpointError" class='errors'>
        <i class="fas fa-exclamation red-color small">&nbsp;</i>
        <span class="my-error">{{ errors.endpointError }}</span>
      </b-col>
      <b-col v-if="errors.username" class='errors'>
        <i class="fas fa-exclamation red-color small">&nbsp;</i>
        <span class="my-error">{{ errors.username[0] }}</span>
      </b-col>
      <pcg-text-input class="my-input" @input="errors.username = null" v-model="sisCredentials.username" :label="$t('views.institutions.list.fields.login')" :showLabel="true"/>
      <b-col v-if="errors.password" class='errors'>
        <i class="fas fa-exclamation red-color small">&nbsp;</i>
        <span class="my-error">{{ errors.password[0]}}</span>
      </b-col>
      <pcg-text-input class="my-input" @input="errors.password = null" type='password' placeholder="********" v-model="sisCredentials.password" :label="$t('views.institutions.list.fields.password')" :showLabel="true"/>
      <b-col v-if="errors.scope" class='errors'>
        <i class="fas fa-exclamation red-color small">&nbsp;</i>
        <span class="my-error">{{ errors.scope[0] }}</span>
      </b-col>
      <pcg-text-input class="my-input" @input="errors.scope = null" v-model="sisCredentials.scope" :label="$t('views.institutions.list.fields.scope')" :showLabel="true"/>
      <b-col v-if="errors.clientId" class='errors'>
        <i class="fas fa-exclamation red-color small">&nbsp;</i>
        <span class="my-error">{{ errors.clientId[0] }}</span>
      </b-col>
      <pcg-text-input class="my-input" @input="errors.clientId = null" v-model="sisCredentials.clientId" :label="$t('views.institutions.list.fields.identifier')" :showLabel="true"/>
      <b-col v-if="errors.clientSecret" class='errors'>
        <i class="fas fa-exclamation red-color small">&nbsp;</i>
        <span class="my-error">{{ errors.clientSecret[0] }}</span>
      </b-col>
      <pcg-text-input class="my-input" @input="errors.clientSecret = null" type='password' placeholder="********" v-model="sisCredentials.clientSecret" :label="$t('views.institutions.list.fields.secret')" :showLabel="true"/>
      <b-col v-if="errors.tokenEndpoint" class='errors'>
        <i class="fas fa-exclamation red-color small">&nbsp;</i>
        <span class="my-error">{{ errors.tokenEndpoint[0] }}</span>
      </b-col>
      <pcg-text-input class="my-input" @input="errors.tokenEndpoint = null" v-model="sisCredentials.tokenEndpoint" :label="$t('views.institutions.list.fields.url_for_login')" :showLabel="true"/>
      <b-col v-if="errors.structureEndpoint" class='errors'>
        <i class="fas fa-exclamation red-color small">&nbsp;</i>
        <span class="my-error">{{ errors.structureEndpoint[0] }}</span>
      </b-col>
      <pcg-text-input class="my-input" @input="errors.structureEndpoint = null" v-model="sisCredentials.structureEndpoint" :label="$t('views.institutions.list.fields.url_to_download_the_structure')" :showLabel="true"/>
      <b-col v-if="errors.generatePackageEndpoint" class='errors'>
        <i class="fas fa-exclamation red-color small">&nbsp;</i>
        <span class="my-error">{{ errors.generatePackageEndpoint[0] }}</span>
      </b-col>
      <pcg-text-input class="my-input" @input="errors.generatePackageEndpoint = null" v-model="sisCredentials.generatePackageEndpoint" :label="$t('views.institutions.list.fields.url_to_generate_the_package')" :showLabel="true"/>
      <b-col v-if="errors.downloadPackageEndpoint" class='errors'>
        <i class="fas fa-exclamation red-color small">&nbsp;</i>
        <span class="my-error">{{ errors.downloadPackageEndpoint[0] }}</span>
      </b-col>
      <pcg-text-input class="my-input" @input="errors.downloadPackageEndpoint = null" v-model="sisCredentials.downloadPackageEndpoint" :label="$t('views.institutions.list.fields.url_to_download_the_package')" :showLabel="true"/>
    </b-modal>
  </div>

</template>

<script>
import ApiSisCredentials from '../../api/sis_credentials'
export default {
  name: 'SisIntegration',
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.integrations.sis_configuration'),
    })
  },
  data: function () {
    return {
      sisCredentials: {
        username: null,
        password: null,
        scope: null,
        clientId: null,
        clientSecret: null,
        tokenEndpoint: null,
        structureEndpoint: null,
        generatePackageEndpoint: null,
        downloadPackageEndpoint: null
      },
      errors: {},
    }
  },
  methods: {
    displayForm () {
      this.errors = {}
      ApiSisCredentials.getSisCredentials()
          .then(result => {
            this.sisCredentials = result.data
          })
      this.$refs.credentialsForm.show()
    },
    credentialsSave (e) {
      e.preventDefault()
      this.errors = {}
      ApiSisCredentials.updateSisCredentials(this.sisCredentials)
          .then(result => {
            this.$refs.credentialsForm.hide()
            this.$toastr.s(this.$t('views.integrations.sis_success'))
          })
          .catch(error => {
            console.log(error)
            this.$toastr.e(this.$t('views.integrations.sis_error'))
            this.errors = error.response.data
          })
    }

  }
}
</script>

<style scoped lang='scss'>
@import '../../assets/stylesheets/vars';

.errors{
  color: red;
  font-size: 12px;
}

.my-header {
  margin-bottom: 2rem;
  font-size: $font-size-l;
  font-weight: bold;
  color: $main-color;
}
.type-checkbox {
  margin: 0 0 1rem 0;
  padding: 0;
}
.info {
  color: #606060;
  font-weight: bold;
  font-size: 1.14em;
  margin-bottom: 2rem;
}
</style>

<style scoped lang='scss'>
@import "../../assets/stylesheets/vars-contrast";

.contrast {
  .my-header {
    color: $main-color;
  }
}
</style>