<template>
  <div>
    <div class="info">
      <span v-for="config in configurationOptions" :key="config.value">
        <span v-if="config.value === parseInt(institutionCredentials.polonConfiguration)">{{ config.text }}</span>
      </span>
    </div>
    <pcg-btn size="small" @click="displayForm()">{{ $t('views.integrations.integration_data') }}</pcg-btn>
      <b-modal ref="credentialsForm"
               :hide-header="true"
               :centered="true"
               footer-class="my-modal-footer"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="credentialsSave"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="my-header">
          <span>{{ $t('views.integrations.integration_modal') }}</span>
        </div>
        <b-col class="type-checkbox">
          <pcg-radio
              name="polonConfiguration"
              v-model="institutionCredentials.polonConfiguration"
              :options="configurationOptions"
              :show-label="true"
              :disabled="true"
              :label="$t('views.institutions.list.fields.config')"
          />
        </b-col>
        <b-col v-if="errors.polonLogin" class='errors'>
          <i class="fas fa-exclamation red-color small">&nbsp;</i>
          <span class="my-error">{{ errors.polonLogin[0] }}</span>
        </b-col>
        <pcg-text-input class="my-input" @input="errors.polonLogin = null" v-model="institutionCredentials.polonLogin" :label="$t('views.institutions.list.fields.login')" :showLabel="true"/>
        <b-col v-if="errors.polonPassword" class='errors'>
          <i class="fas fa-exclamation red-color small">&nbsp;</i>
          <span class="my-error">{{ errors.polonPassword[0]}}</span>
        </b-col>
        <pcg-text-input class="my-input" @input="errors.polonPassword = null" type='password' placeholder="********" v-model="institutionCredentials.polonPassword" :label="$t('views.institutions.list.fields.password')" :showLabel="true"/>
        <b-col v-if="errors.polonInstitutionId" class='errors'>
          <i class="fas fa-exclamation red-color small">&nbsp;</i>
          <span class="my-error">{{ errors.polonInstitutionId[0] }}</span>
        </b-col>
        <pcg-text-input class="my-input" @input="errors.polonInstitutionId = null" v-model="institutionCredentials.polonInstitutionId" :label="$t('views.institutions.list.fields.institutionId')" :showLabel="true"/>
      </b-modal>
  </div>
  
</template>

<script>
import ApiInstitutions from '../../api/institutions' 
import IpoLoader from '../../components/IpoLoader'
export default {
  name: 'IntegrationPolon',
  components: {
    IpoLoader
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.integrations.polon'),
    })
  },
  data: function () {
    return {
      institutionCredentials: {
        polonLogin: null,
        polonPassword: null,
        polonInstitutionId: null,
        polonConfiguration: '0',
        institutionId: null
      },
      errors: {},
      configurationOptions: [
        {
          text: this.$t('views.institutions.list.configuration_types.demo'), value: '0'
        },
        {
          text: this.$t('views.institutions.list.configuration_types.prod'), value: '1'
        },
      ]
    } 
  },
  created () {
    ApiInstitutions.getPolonCredentials()
      .then(result => {
        this.institutionCredentials.polonConfiguration = String(result.data.configuration)
      })
  },
  methods: {
    displayForm () {
      ApiInstitutions.getPolonCredentials()
      .then(result => {
        this.institutionCredentials.polonLogin = result.data.polonLogin
        this.institutionCredentials.polonInstitutionId = result.data.polonInstitutionId
        this.institutionCredentials.polonConfiguration = String(result.data.configuration)
        this.institutionCredentials.institutionId = result.data.institutionId
      })
      this.$refs.credentialsForm.show()
    },
    credentialsSave (e) {
      e.preventDefault()
      this.errors = {}
      ApiInstitutions.updatePolonCredentials(this.institutionCredentials)
          .then(result => {
            this.$refs.credentialsForm.hide()
          })
          .catch(error => {
            console.log(error)
            this.$toastr.e(this.$t('views.integrations.polon_error'))
            this.errors = error.response.data
          })
    }
  }
}
</script>

<style scoped lang='scss'>
@import '../../assets/stylesheets/vars';

  .errors{
    color: red;
    font-size: 12px;
  }

  .my-header {
    margin-bottom: 2rem;
    font-size: $font-size-l;
    font-weight: bold;
    color: $main-color;
  }
  .type-checkbox {
    margin: 0 0 1rem 0;
    padding: 0;
  }
  .info {
    color: #606060;
    font-weight: bold;
    font-size: 1.14em;
    margin-bottom: 2rem;
  }
</style>

<style scoped lang='scss'>
@import "../../assets/stylesheets/vars-contrast";

.contrast {
  .my-header {
    color: $main-color;
  }
}
</style>