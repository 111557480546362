import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { en } from './en'
import { pl } from './pl'

Vue.use(VueI18n)

const getOldChoiceIndex = VueI18n.prototype.getChoiceIndex
VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
  if (this.locale !== 'pl') {
    return getOldChoiceIndex.call(this, choice, choicesLength)
  }

  if (choicesLength === 4) {
    if (choice === 0) {
      return 0
    }
    if (choice === 1) {
      return 1
    }
    if (choice > 1 && choice < 5) {
      return 2
    }
    return 3
  }

  return getOldChoiceIndex.call(this, choice, choicesLength)
}

const messages = {
  en,
  pl
}

const dateTimeFormats = {
  pl: {
    short: {
      day: 'numeric', month: 'numeric', year: 'numeric'
    },
    longDate: {
      day: 'numeric', month: 'long', year: 'numeric'
    },
    long: {
      day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'
    }
  },
  en: {
    short: {
      day: 'numeric', month: 'numeric', year: 'numeric'
    },
    longDate: {
      day: 'numeric', month: 'long', year: 'numeric'
    },
    long: {
      day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'
    }
  }
}

export const i18n = new VueI18n({
  locale: 'pl',
  messages,
  dateTimeFormats
})
