import api from './index'
import qs from 'qs'

const resource = '/users'

export default {
  getUsers (query) {
    let url = ''
    const params = qs.stringify(query)
    url = resource + '?' + params
    return api.get(url, query)
  },
  updateRoles (id, roles, facultyId) {
    let url = resource
    url += '/' + id + '/update_roles'
    const body = {
      id: id,
      faculty_id: facultyId,
      roles: roles
    }
    return api.put(url, body)
  },
  createUser (user) {
    return api.post(resource, user)
  },
  updateUser (user) {
    const uri = `${resource}/${user.id}`
    const body = {
      user: user
    }
    return api.put(uri, body)
  },
  deleteUser (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  }
}
