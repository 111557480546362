<template>
  <div class="d-flex align-items-center import-status">
    <div v-if="status === 0" class='status-spinner'>
      <div class="custom-loader green-loader"></div>
      <span class='green'>{{ $t('views.imports.list.status.in_progress') }}</span>
    </div>
    <div v-else-if="status === 1" class="import-status__container">
      <i class="fas fa-check orange-color small">&nbsp;</i>
      <span>{{ $t('views.imports.list.status.imported') }}</span>
    </div>
    <div v-else-if="status === 2" class="import-status__container">
      <i class="fas fa-times red-color small">&nbsp;</i>
      <span>{{ $t('views.imports.list.status.import_failed') }}</span>
    </div>
    <div v-else-if="status === 3" class="import-status__container">
      <i class="fas fa-check green-color small">&nbsp;</i>
      <span>{{ $t('views.imports.list.status.exported') }}</span>
    </div>
    <div v-else-if="status === 4" class="import-status__container">
      <i class="fas fa-times red-color small">&nbsp;</i>
      <span>{{ $t('views.imports.list.status.canceled') }}</span>
    </div>    
  </div>
</template>

<script>
import Spinner from './Spinner'
export default{
  components: {
    Spinner
  },
  name: 'SisImportStatus',
  props: {
    className: {
      type: String,
      default: ''
    },
    status: {
      required: true,
      type: Number
    }
  }
}
</script>

<style scoped lang="scss">

@import '../assets/stylesheets/vars.scss';
.import-status{
  .status-spinner span.green{
    color: #7ED376;
  }
  .status-spinner span.orange{
    color: #EFC86B;
  }
  &__container, .status-spinner{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding-left: 0;
    i{
      height: 28px;
      width: 28px;
      min-width: 28px;
      border-radius: 50%;
      position: relative;
      &::before{
        width: 20px;
        height: 20px;
        position: absolute;
      }
    }
    .red-color {
      background-color: #FDC7C8;
      color: #FA4C4D;
      & + span{
        color: #FA4C4D;
      }
      &::before{
        left: 10px;
        top: 8px;
      }
    }
    .orange-color {
      background-color: #EFC86B;
      color: $pcg-white;
      & + span{
        color: #EFC86B;
      }
      &::before{
        left: 10px;
        top: 8px;
      }
      &.fa-check::before{
        left: 8px;
        top: 8px;
      }
    }
    .green-color {
      background-color: #CCEEC8;
      color: #7ED376;
      & + span{
        color: #7ED376;
      }
      &::before{
        left: 8px;
        top: 8px;
      }
    }
  }
  .status-spinner{
    padding-left: 0;
    :deep(svg){
      width: 28px;
      height: 28px;
    }
  }
}

.custom-loader {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
  animation:s3 1s infinite linear;
}

.green-loader{
  background:
      radial-gradient(farthest-side,#7ED376 94%,#0000) top/4px 4px no-repeat,
      conic-gradient(#0000 30%,#7ED376);
}

.orange-loader{
  background:
      radial-gradient(farthest-side,#EFC86B 94%,#0000) top/4px 4px no-repeat,
      conic-gradient(#0000 30%,#EFC86B);
}

@keyframes s3{
  100%{transform: rotate(1turn)}
}

</style>