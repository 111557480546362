<template>
  <div class="history-element">
    <b-row>
      <b-col>
        <div class="element-student">
          <div class='student'>
            <span class="author-name">{{history.who || 'System'}}</span>
            <span class="changes-text">
              {{whatHappened(history.action)}} {{whatModel(history.itemType)}}
            </span>
            <!-- <p><span>{{history.who || 'System'}}</span> {{whatHappened(history.action)}} {{whatModel(history.itemType)}}</p> -->
            <!-- <p>{{ $d(Date.parse(history.actionAt)) }} {{ setTime(history.actionAt) }}</p> -->
          </div>
          <div class="date">
            {{ $d(new Date(history.actionAt),"long") }}
          </div>
        </div>
      </b-col>
      <b-col class="text-center col-1">
        <div class="my-hide" v-on:click="detailsVisible = !detailsVisible">
          <i v-if="detailsVisible" class="fas fa-angle-up"></i>
          <i v-else class="fas fa-angle-down"></i>
        </div>
      </b-col>
    </b-row>
    <b-collapse id="filters-collapse" v-model="detailsVisible">
      <div class="element-changes student-history">
        <div class="student-history__details--item"
          v-for='(key, index) in Object.keys(history.changes)'
          :key='index'
        > 
          <div v-if='key !== "updatedAt"'>
            <label class='history-item__title'>
              {{$t(`views.students.list.fields.${key}`)}}
            </label>
            <div class='history-item__changes'>
              <div v-if="history.action !== 'destroy'" class='history-item__changes--after'>
                <span> {{changeValue(history.changes[key][1])}}  </span>
                <span>   {{$t(`views.students.list.after`)}} </span>
              </div>
              <div  v-if="history.action !== 'create'" class='history-item__changes--before'>
                <span> {{changeValue(history.changes[key][0])}}  </span>
                <span>  {{$t(`views.students.list.before`)}} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <hr/>
  </div>
</template>

<script>
export default {
  name: "StudentHistoryElement",
  data: function () {
    return {
      detailsVisible: false,
    }
  },
  props: {
    history: Object
  },
  methods: {
    setTime (date) {
      const momentDate = moment(date)
      return momentDate.format('LT')
    },
    changeValue(val){
      if (val === true || val === 'true'){
        return this.$t('general.yes')
      } else if (val === false || val === 'false'){
        return this.$t('general.no')
      } else if (Array.isArray(val)) {
        return  val.join(', ')
      } else {
        return val
      }
    },
    whatHappened(action) {
      let information = ""
      if (action === 'create') {
        information = "stworzył/a"
      } else if ((action === 'destroy')) {
        information = "usunął/eła"
      } else {
        information = "zmienił/a dane"
      }
        return information
    },
    whatModel(item_type) {
      return this.$t(`views.students.list.models.${item_type}`)
    }
  }
}
</script>

<style scoped lang='scss'>
@import '../assets/stylesheets/vars.scss';

.student-history{
  display: flex;
  flex-direction: column;
  :deep(.btn-light){
    width: 100%;
    background: transparent !important;
    border: transparent;
    color: grey !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0px;
    @media (max-width: 500px){
      height: 5rem !important;
    }
    &:hover{
      background: transparent;
      border: transparent;
      color: grey;
    }
    &::after{
      color: grey;
      margin-top: 5px;
    }
  }
  &__info{
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 500px){
      flex-direction: column;
    }
    span{
      color: $main-active-color;
      font-weight: bold;
    }
  }
  .student-history{
    &__details--item{
      .history-item__title{
        margin-top: 1rem;
      }
      .history-item__changes{
        & > div{
          border-radius: 3px;
          padding: 0.5rem 1rem;
          margin: 4px 0;
          display: flex;
          justify-content: space-between;
          @media (max-width: 500px){
            flex-direction: column;
          }
        }
        &--before{
          background-color: #FDC7C8;
          color: #FA4C4D;
        }
        &--after{
          background-color: #CCEEC8;
          color: #7ED376;
        }
      }
    }
  }
  :deep(.student-history__button){
    display: block !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
</style>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .history-elements {
    margin-top: 2rem;
  }
  .element-student {
    display: flex;
    justify-content: space-between;

    .student {
      .author-name {
        font-weight: bold;
        color: $main-active-color;
      }

      .changes-text {
        color: $pcg-dark-gray;
      }
    }

    .date {
      color: $pcg-gray;
      font-weight: 400;
    }
  }
  .element-changes {
    margin-top: 1.5rem;
    .key {
      color: $pcg-dark-gray;
      margin-bottom: .2rem;
    }
    .new-value {
      color: $alert-success;
      background-color: lighten($alert-success, 35%);
      margin-bottom: .5rem;
      border-radius: 5px;
      padding: .5rem 1rem;
      font-weight: 400;
    }
    .old-value {
      color: $alert-error;
      background-color: lighten($alert-error, 25%);
      border-radius: 5px;
      padding: .5rem 1rem;
      font-weight: 400;
    }
  }

  .my-hide {
    cursor: pointer;
    color: $main-active-color;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .element-student {
      .student {
        .author-name {
          color: $main-active-color;
        }
        .changes-text {
          color: $pcg-dark-gray;
        }
      }
      .date {
        color: $pcg-gray;
      }
    }
    .element-changes {
      .key {
        color: $pcg-dark-gray;
      }
      .new-value {
        color: $alert-success;
        background-color: lighten($alert-success, 35%);
      }
      .old-value {
        color: $alert-error;
        background-color: lighten($alert-error, 25%);
      }
    }
    .my-hide {
      color: $main-active-color;
    }
  }
</style>